import React, {Component} from 'react';
import Readfile from "./Readfile";
import crypto from "crypto";
import axios from "axios";
import Plot from 'react-plotly.js';
import { CSVLink } from "react-csv";
//import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from "../css-common";
import Logocerm from "../images/CERM_CIRMMP_resized.png";
import Logoegi from "../images/logo-egi.png";
import Logoeosc from "../images/eosclogo.png";
import Logoinext from "../images/inext.png";
import {
    Typography,
    Paper,
    Grid,
    Button,
  //  CssBaseline,
    Checkbox,
    AppBar,
    Toolbar,
    withStyles,
    CircularProgress,
    Link,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Tooltip,
    Box,
    Fade
} from '@material-ui/core';

class Peakass extends Component {

    state = {
        loading: false,
        freecsv: [],
        withligandcsv: [],
        outcsv: [],
        est_shift: [],
        real_shift: [],
        residueKey: [],
        pred_x: [],
        pred_y: [],
        x: [],
        y: [],
        assigned_to: [],
        target_x:[],
        target_y: [],
        csp_avg: [],
        cost: [],
        order: [],
        plotfreecoord: [],
        plotligandcoord: [],
        labeled: false,
        n_scale: "5.0",
        n_scale_error: false,
        method_name: "RASmart",
        stop_submit: false,
        upf1: false,
        upf2: false,
        checkabout: false

    }

    checkAbout = () => {
        let lastc = this.state.checkabout;
        this.setState({checkabout: !lastc})
    }

    claerpage = () => {
        window.location.reload();
    }

    check_n_scale = (event) => {
        console.log("OLD Nscale", this.state.n_scale);
        console.log("change n_scate", event.target.value);
        let val = event.target.value
        let lenght =event.target.value.length
        if (isNaN(val))
        {
            console.log("insnan")
            // this.setState({n_scale: "5.0"});
            this.setState({n_scale_error: true, stop_submit: true})
        } else if ( (parseFloat(val) < 0.1 || parseFloat(val) > 20.0) || lenght > 4 || lenght === 0 ) {
            console.log("qui1")
            //this.setState({n_scale: "5.0"});
            this.setState({n_scale_error: true, stop_submit: true})
        } else {
            console.log("qui2")
            this.setState({n_scale: val});
            this.setState({n_scale_error: false, stop_submit: false})
        }
        console.log("Nscale", this.state.n_scale);
    }
    check_method_name = (event) => {
        console.log("set method_name", event.target.value);
        this.setState({method_name: event.target.value});
    }

    checklabeled = () => {
        console.log("set labeled");
        if (this.state.labeled === false) {this.setState({labeled: true});}
        else {this.setState({labeled: false});}
    }

    addcsv1 = (e, txt) => {
        console.log(txt);
        console.log(e);
        this.setState({freecsv: [...txt], upf1: true})
    }
    addcsv2 = (e, txt) => {
        console.log(txt);
        console.log(e);
        this.setState({withligandcsv: [...txt], upf2: true})
    }
    submitcalc = () => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };
        this.setState({loading: true});
        let uid = crypto.randomBytes(10).toString('hex');
        const restdata = {
            crypto: uid,
            freecsv: [...this.state.freecsv],
            withligandcsv: [...this.state.withligandcsv],
            labeled: this.state.labeled,
            n_scale: this.state.n_scale,
            method_name: this.state.method_name
        }
        console.log(this.state);
        console.log("----------- POSTDATA --------");
        console.log(restdata);
        //axios.post('http://ffmetal.cerm.unifi.it/restzn/sendpdb', downloadPDB, axiosConfig)
        ///
        //axios.post('http://localhost:8080/rest/runpeakass', restdata, axiosConfig)
        //axios.post('http://picasso.cerm.unifi.it:8080/rest/runpeakass', restdata, axiosConfig)
        axios.post('https://picasso.cerm.unifi.it/rest/runpeakass', restdata, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log(res.data);
                console.log("----------CSVOUT------------")
                console.log(res.data.csvout.residueKey);
                console.log(res.data.csvout.est_shift);
                console.log("----------CSVOUT------------")
                this.setState({
                    loading: false,
                    status: res.data.status,
                    outcsv: [...res.data.outcsv],
                    est_shift: res.data.csvout.est_shift.map(a => (Number(a) > 0 ? Number(a) : null)),
                    residueKey: [...res.data.csvout.residueKey],
                    real_shift: res.data.csvout.real_shift.map(a => (Number(a) > 0 ? Number(a) : null)),
                    pred_x: res.data.csvout.pred_x.map(a => (Number(a) > 0 ? Number(a) : null)),
                    pred_y: res.data.csvout.pred_y.map(a => (Number(a) > 0 ? Number(a) : null)),
                    x: res.data.csvout.x.map(a => (Number(a) > 0 ? Number(a) : null)),
                    y: res.data.csvout.y.map(a => (Number(a) > 0 ? Number(a) : null)),
                    target_x: res.data.csvout.target_x.map(a => (Number(a) > 0 ? Number(a) : null)),
                    target_y: res.data.csvout.target_y.map(a => (Number(a) > 0 ? Number(a) : null)),
                    csp_avg: res.data.csvout.csp_avg.map(a => (Number(a) > 0 ? Number(a) : null)),
                    cost: res.data.csvout.cost.map(a => (Number(a) > 0 ? Number(a) : null)),
                    assigned_to:  res.data.csvout.assigned_to.map(a => (a.length > 0 ? a : null)),
                    order: res.data.csvout.order.map(a => (Number(a) > 0 ? Number(a) : null))
                });

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                this.setState({
                    loading: false,});
                //this.setState({loadingerror: [...err.response.data.infoout]})
            });
    }

    checkresidues = (data) => {
        console.log("-------checkresidues............ INI")
        console.log(data);

        console.log(data.points[0].label);
        let label = data.points[0].label;
        let index = this.state.residueKey.indexOf(label);
        console.log(this.state.x[index], this.state.y[index], this.state.pred_x[index], this.state.pred_y[index])
        this.setState({
            plotfreecoord: [this.state.x[index], this.state.pred_x[index]],
            plotligandcoord: [this.state.y[index], this.state.pred_y[index]]
        })
        console.log("-------checkresidues............ END")
    }
    downloadtar = () => {
        axios({
            url: 'https://picasso.cerm.unifi.it/rest/download',
            //url: 'http://localhost:8080/rest/download', //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'example_picasso.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }

    render() {
        const classapg = {position: 'fixed', bottom: 0, height: "5%", maxWidth: 1024, display: 'none'}
        const classap = {position: 'fixed', bottom: 0, maxWidth: 1024}
        const {loading} = this.state;
        const { classes } = this.props
        console.log(this.state.labeled);
        console.log(this.state.outcsv);
        console.log(this.state);
        let dataplot = "";
        let csvdata = "";
        let headers =[];
        let datac = [];

        const about = (
            <Paper sx={{ m: 2 }} elevation={4}>
                <Box  sx={{ width: 400, height: 100 }}>
                    <Box>
                        <br></br>
                    <Link  href="https://github.com/cerm-cirmmp/picasso.git" variant="body2" underline="always" > GitHub site</Link>
                        {/*<Link  href="https://github.com/cerm-cirmmp/picasso.git" variant="body2"> Contact us</Link>*/}
                        <br></br>
                        <Link  href="mailto:giachetti@cerm.unifi.it" variant="body2" underline="always" >Contact us</Link>
                        <br></br>
                        <Link  href="http://doi.org/10.1021/acs.jcim.1c00871" variant="body2" underline="always" >Reference</Link>
                    </Box>
                </Box>
            </Paper>
        );

        if (this.state.outcsv.length > 0) {
            //ResidueKey;x;y;order;assigned_to;c;real_shift;pred_x;pred_y;target_x;target_y
            if (this.state.labeled === false) {
                headers = [
                    {label: "ResidueKey", key: "residuekey"},
                    {label: "Order", key: "order"},
                    {label: "x", key: "x"},
                    {label: "y", key: "y"},
                    {label: "Assigned_to", key: "assigned_to"},
                    //{label: "Order", key: "order"},
                    //{label: "Assigned_to", key: "assigned_to"},
                    //{label: "C", key: "c"},
                    //{label: "Real_Shift", key: "real_shift"},
                    {label: "Pred_x", key: "pred_x"},
                    {label: "Pred_y", key: "pred_y"},
                    {label: "Est_shift", key: "est_shift"},

                    //{label: "Target_x", key: "target_x"},
                    //{label: "Target_y", key: "target_y"}
                ];
                let i;
                for (i = 0; i < this.state.residueKey.length; i++) {
                    datac.push({
                        residuekey: this.state.residueKey[i], x: this.state.x[i], y: this.state.y[i],
                        real_shift: this.state.real_shift[i], pred_x: this.state.pred_x[i], pred_y: this.state.pred_y[i],
                        order: this.state.order[i],assigned_to: this.state.assigned_to[i],est_shift: this.state.est_shift[i]
                    });
                }
            } else {
                headers = [
                    {label: "ResidueKey", key: "residuekey"},
                    {label: "Order", key: "order"},
                    {label: "x", key: "x"},
                    {label: "y", key: "y"},
                    {label: "Assigned_to", key: "assigned_to"},
                    {label: "Pred_x", key: "pred_x"},
                    {label: "Pred_y", key: "pred_y"},
                    {label: "Est_shift", key: "est_shift"},
                    {label: "Real_Shift", key: "real_shift"},
                    {label: "Target_x", key: "target_x"},
                    {label: "Target_y", key: "target_y"},
                    {label: "Csp_avg", key: "csp_avg"},
                    {label: "Cost", key: "cost"}
                ];
                let i;

                for (i = 0; i < this.state.residueKey.length; i++) {
                    datac.push({
                        residuekey: this.state.residueKey[i], x: this.state.x[i], y: this.state.y[i],
                        real_shift: this.state.real_shift[i], pred_x: this.state.pred_x[i], pred_y: this.state.pred_y[i],
                        target_x: this.state.target_x[i], target_y: this.state.target_y[i], est_shift: this.state.est_shift[i],
                        csp_avg: this.state.csp_avg[i],cost: this.state.cost[i], order: this.state.order[i], assigned_to: this.state.assigned_to[i]
                    });
                }
            }
            // const datac = [
            //     {firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36"},
            //     {firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76"},
            //     {firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57"},
            //     {firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42"},
            //     {firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56"},
            //     {firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23"},
            //     {firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87"},
            //     {firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78"},
            //     {firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22"},
            //     {firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35"},
            //     {firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63"}
            // ];

            const csvReport = {
                data: datac,
                headers: headers,
                filename: 'Picasso.csv'
            };

             csvdata = (
                 <Paper>
                     <Grid container spacing={3}>
                         <Grid item xs={4} >
                             <Typography variant="h6" gutterBottom>
                            Export data to CSV format
                             </Typography>
                         </Grid>
                         <Grid item xs={4}>
                             <CSVLink {...csvReport}>
                                 <Typography variant="h7" gutterBottom >
                                 Export to CSV
                                 </Typography>
                             </CSVLink>
                         </Grid>
                     </Grid>
                 </Paper>
            );
        }
        if (this.state.outcsv.length > 0) {
            // csvtojason({
            //     noheader:true,
            //     output: "csv"
            // })
            //     .fromString(jsoncsv.toString())
            //     .then((csvRow)=>{
            //         console.log(csvRow) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
            //     })
            const data = this.state.est_shift;
            //const data = this.state.csvout.est_shift.map(a=>Number(a));
            console.log("------CSVOUT-------")

            let datasca = [

                {
                    type: 'scatter',
                    mode: 'markers+text',
                    name: 'Free',
                    x: [...this.state.pred_x],
                    y: [...this.state.pred_y],
                    text: [...this.state.residueKey],
                    marker_color: 'blue',
                    textfont: {
                        family: 'Times New Roman'
                    },
                    textposition: 'bottom center',
                    marker: {size: 12}
                },
                {
                    type: 'scatter',
                    mode: 'markers+text',
                    name: 'Ligand',
                    x: [...this.state.x],
                    y: [...this.state.y],
                    text: [...this.state.residueKey],
                    marker_color: 'red',
                    textfont: {
                        family: 'Times New Roman'
                    },
                    textposition: 'bottom center',
                    marker: {size: 12}
                }
            ]
            if (this.state.plotfreecoord.length > 0) {
                datasca.push(
                    {
                        type: 'lines',
                        x: [...this.state.plotfreecoord],
                        y: [...this.state.plotligandcoord],
                        showlegend: false
                    })
            }

            //[...Array(this.state.est_shift.length).keys()].map(i =>i+1)
            if (data.length > 0) {
                let datahist = [
                    {
                        type: 'bar',
                        name : "Pred",
                        x: [...this.state.residueKey],
                        y: [...this.state.est_shift],
                        // text: this.state.residueKey.map(a=>"Pred"),
                        marker_color: 'blue'
                    }
                ]
                if (this.state.real_shift.length > 0) {
                    datahist.push(
                        {
                            type: 'bar',
                            name: "Exp",
                            x: [...this.state.residueKey],
                            y: [...this.state.real_shift],
                            // text: this.state.residueKey.map(a=>"Exp"),
                            marker_color: 'red'
                        }
                    )
                }
                dataplot = (
                    <div>
                        <Plot
                            data={datahist}
                            layout={{width: 1024,
                                height: 768, title: ' ',
                                barmode: 'group',
                                xaxis_tickangle: -45,
                                uirevision:'true',
                                xaxis: {autorange: true},
                                yaxis: {autorange: true}}}
                            onClick={(data) => this.checkresidues(data)}
                            onHover={(data) => this.checkresidues(data)}
                            onUnhover={() => console.debug("onUnhover")}
                        />
                        <Plot
                            data={datasca}
                            layout={{
                                width: 1024,
                                height: 768,
                                title: '   ',
                                yaxis: {autorange: "reversed"},
                                xaxis: {autorange: "reversed"}
                            }}
                        /></div>
                );
            }
            console.log(data);
        }
        return (
            <div style={{padding: 16, margin: 'auto', maxWidth: 1024}}>

                <AppBar className={classes.appBar} position="static" style={{backgroundColor: "#343A40"}}>
                    <Toolbar>
                        <Grid container>
                            <Grid item md={6} xs={6} sm={6}>
                        <Typography style={{marginTop: 15}} variant="h4" align="left">
                            PICASSO
                        </Typography>
                                <Typography variant="h8" align="center">
                                    Automated Determination of Nuclear Magnetic Resonance Chemical Shift Perturbations in Ligand Screening Experiments
                                </Typography>
                            </Grid>
                            <Grid item   md={6} xs={6} sm={6}>
                            <img src={Logocerm} alt={"CERM logo"} style={{
                                width: '50%',
                                paddingTop: 10,
                                float: 'right',
                                paddingRight: 10,
                                paddingLeft: 10,
                                backgroundColor: "white"
                            }}/>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>

                <Paper style={{padding: 16}}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Readfile addfile={(e, txt) => this.addcsv1(e, txt)}>Upload free csv </Readfile>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Readfile addfile={(e, txt) => this.addcsv2(e, txt)}>Upload ligand csv </Readfile>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Tooltip title={"Heteronuclear shifts will be divided by this scale factor to calculate the combined shifts"}>
                            <TextField id="standard-basic"
                                       onChange={(event) => this.check_n_scale(event)}
                                       defaultValue={this.state.n_scale}
                                       error={ this.state.n_scale_error}
                                       label="Het-scale (0.1 to 20.0)" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Tooltip title={"The Smart method uses residue assignments to favor clusters of similarly shifted residues; the Proximity method does not use assignments and it looks only at peak proximity"}>
                            <InputLabel id="demo-simple-select-helper-label">Select method</InputLabel>
                            </Tooltip>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.method_name}
                                onChange={this.check_method_name}
                            >
                                <MenuItem value={"RASmart"}>Smart</MenuItem>
                                <MenuItem value={"RA"}>Proximity</MenuItem>
                            </Select>

                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Checkbox
                                checked={this.state.labeled}
                                onChange={this.checklabeled}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />Input labeled
                        </Grid>

                    </Grid>
                </Paper>
                <Grid container spacing={1}>
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={this.downloadtar}
                    >
                        <Typography variant="h8" gutterBottom>
                            Download examples
                        </Typography>
                    </Link>
                </Grid>
                </Grid>
                <Paper>

                </Paper>
                <Paper>
                    <Grid container spacing={4}>
                        <Grid   item  xs={3}>
                            {/*{(<CircularProgress disableShrink />)}*/}
                            <Button variant="contained" color="primary" style={{marginLeft: 15}}
                                    disabled={(this.state.loading || this.state.stop_submit) || !(this.state.upf1 && this.state.upf2)}
                                    onClick={this.submitcalc}> Submit</Button>
                        </Grid>
                        <Grid item  xs={3}>

                            <Button variant="contained" color="secondary" disabled={this.state.loading}
                                    onClick={this.claerpage}> Clear Page</Button>

                        </Grid>
                        {loading &&

                            <Grid item xs={1} >
                                <CircularProgress/>
                            </Grid>

                        }
                        {loading &&

                            <Grid item xs={4} >
                                <Typography variant="h6" gutterBottom>
                                    Please Wait
                                </Typography>
                            </Grid>

                        }
                    </Grid>
                </Paper>

                <Paper style={{padding: 16}}>
                    <Grid item style={{marginTop: 5}} xs={12}>
                        {csvdata}
                    </Grid>
                </Paper>
                <Paper style={{padding: 16}}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={16}>
                            {dataplot}
                        </Grid>
                    </Grid>

                </Paper>
                <AppBar className={classes.appBar} position="static"
                        style={(this.state.outcsv.length > 0) ? classapg : classap}>

                    <Toolbar style={{float: 'right'}}>
                        <a href={"https://www.egi.eu/projects/egi-ace/"}></a>
                        <img src={Logoeosc} alt={"EOSC-life logo"} style={{
                            height: '50%',
                            paddingTop: 1,
                            marginRight: 20,
                            float: 'right',
                            backgroundColor: "white"
                        }}/>

                        <a href={"https://www.egi.eu/projects/egi-ace/"}></a>
                        <img src={Logoegi} alt={"EGI logo" }
                             style={{height: '50%', paddingTop: 1, marginRight: 20, float: 'right', backgroundColor: "white"}}/>

                        <a href={"https://inext-discovery.eu/network/inext-d/home"}></a>
                            <img src={Logoinext} alt={"iNext logo" }
                                 style={{height: '50%', paddingTop: 1, float: 'right', backgroundColor: "white"}}/>

                        <Box sx={{ display: 'flex' }}>
                            <Fade in={this.state.checkabout}>{about}</Fade>
                        </Box>
                        <Button style={{position: "absolute", right: "10px"}} onClick={this.checkAbout}>About</Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

export default withStyles(styles)(Peakass);