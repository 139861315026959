import Peakass from "./components/Peakass";
function App() {
  return (
    <div className="App">

      <Peakass>

      </Peakass>
    </div>
  );
}

export default App;
